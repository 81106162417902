<template>
  <div class="d-flex mb-2">
    <div class="flex-fill">
      <div class="input-group">
        <search />
      </div>
    </div>
    <div class="ms-2">
      <div style="min-width: 180px" class="d-flex justify-content-between">
        <span
          class="d-inline-block"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          :data-bs-original-title="$t('ledgers_page.enable_data_stream')"
          v-tooltip
        >
          <button
            class="btn text-primary border"
            :class="{
              'bg-opacity-25': streaming,
              'bg-body': !streaming,
            }"
            @click="startStream"
            :disabled="streaming"
          >
            <i class="bi bi-play"></i>
          </button>
        </span>
        <span
          class="d-inline-block"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          :data-bs-original-title="$t('ledgers_page.pause_data_stream')"
          v-tooltip
        >
          <button
            class="btn text-primary border"
            :class="{
              'bg-opacity-25': !streaming,
              'bg-body': streaming,
            }"
            @click="pauseStream"
            :disabled="!streaming"
          >
            <i class="bi bi-pause"></i>
          </button>
        </span>
        <span
          class="d-inline-block"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          :data-bs-original-title="$t('ledgers_page.previous_page')"
          v-tooltip
        >
          <button
            class="btn text-primary border"
            @click="prevPage()"
            :disabled="disablePrevBtn"
          >
            <i class="bi bi-arrow-left"></i></button
        ></span>
        <span
          class="d-inline-block"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          :data-bs-original-title="$t('ledgers_page.next_page')"
          v-tooltip
        >
          <button
            class="btn text-primary border"
            @click="nextPage()"
            :disabled="disableNextBtn"
          >
            <i class="bi bi-arrow-right"></i></button
        ></span>
      </div>
    </div>
  </div>
  <ledger-summary :rows="apiLimit" />
</template>

<script>
import { defineComponent, computed, onUnmounted } from "vue";
import { useStore } from "vuex";
import LedgerSummary from "@/components/summaries/LedgerSummary.vue";
import Search from "@/components/Search.vue";
export default defineComponent({
  name: "Ledgers",
  components: {
    LedgerSummary,
    Search,
  },
  setup() {
    const store = useStore();
    store.dispatch("ledgers/startStreaming", process.env.VUE_APP_API_LIMIT);
    const nextPage = () => {
      store.dispatch("ledgers/fetchNextPage");
    };
    const prevPage = () => {
      store.dispatch("ledgers/fetchPrevPage");
    };
    const startStream = () => {
      store.dispatch("ledgers/startStreaming", process.env.VUE_APP_API_LIMIT);
    };
    const pauseStream = () => {
      store.dispatch("ledgers/stopStreaming");
    };
    const disablePrevBtn = computed(
      () => store.getters["ledgers/disablePrevBtn"]
    );
    const disableNextBtn = computed(
      () => store.getters["ledgers/disableNextBtn"]
    );
    const streaming = computed(() => store.getters["ledgers/isStreaming"]);
    onUnmounted(pauseStream);
    return {
      nextPage,
      prevPage,
      disablePrevBtn,
      disableNextBtn,
      startStream,
      pauseStream,
      streaming,
      apiLimit: parseInt(process.env.VUE_APP_API_LIMIT),
    };
  },
});
</script>

    
